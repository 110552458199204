<template>
    <div class="view pa24">
        <el-tabs v-model="activeName" @tab-click="tabClick">
           <el-tab-pane label="官方模版" name="0">
               <ModelTable type="0" ref="modelTable0"></ModelTable>
           </el-tab-pane>
           <el-tab-pane label="我的模版" name="1">
               <ModelTable type="1" ref="modelTable1"></ModelTable>
           </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import ModelTable from "./components/ModelTable"

export default {
    name: "rytModel",
    components: {
        ModelTable,
    },
    data() {
        return {
            activeName: 0,
        }
    },
    created() {
    },
    mounted() {
        this.$refs.modelTable0.init()
    },
    methods: {
        tabClick(tab, event) {
            this.activeName = tab.name
            if(this.activeName == 0) {
              this.$refs.modelTable0.init()
            }else {
              this.$refs.modelTable1.init()
            }
        },
    }
}
</script>
<style scoped>
</style>
