<template>
  <div class="view pa24">
    <div>
      <el-form label-width="100" :inline="true">
        <el-form-item label="标题">
          <el-input v-model="title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="模板类型">
            <el-select v-model="type" placeholder="请选择">
                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
        </el-form-item> -->
        <el-button size="medium" type="primary" @click="handleSearchData">搜索</el-button>
        <el-button size="medium" @click="handleReset">重置</el-button>

      </el-form>
    </div>

    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum" :loading="loading" :total="total">
      <template v-slot:table>
        <el-table-column align="center" :show-overflow-tooltip="true" prop="title" label="标题"/>
        <el-table-column align="center" :show-overflow-tooltip="true" prop="image" label="缩略图">
          <template slot-scope="scope">
            <div class="demo-image__preview" >
              <el-image :preview-src-list="[baseImgUrl+scope.row.image]" style="width: 100px; height: 100px" :src="baseImgUrl + scope.row.image"></el-image>
            </div>
          </template>

        </el-table-column>

        <el-table-column align="center" :show-overflow-tooltip="true" prop="type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type == 0 || !scope.row.type ? '官方模板' : '用户模板' }}
          </template>
        </el-table-column>
        <el-table-column align="center" :show-overflow-tooltip="true" prop="createTime" label="创建时间"/>
        <el-table-column align="center" :show-overflow-tooltip="true" prop="nickeName" label="创建人" v-if="type==1"/>
        <el-table-column prop="" label="操作" align="center" v-if="type == 1">
          <template slot-scope="scope">
<!--            <el-button type="text" @click="changeModelType(scope.row)">{{-->
<!--                scope.row.type == 0 ? '移出官方模板库' :-->
<!--                    '加入官方模板库'-->
<!--              }}-->
<!--            </el-button>-->
            <el-button type="text" style="color: red" @click="delModel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import {fetchRytModel, fetchChangeModelType, fetchDelModel} from '@/api/ryt.js'
import {baseImgUrl} from '@/utils/constant'

export default {
  name: "rytModel",
  components: {
    commonTable,
  },
  props: ['type'],
  data() {
    return {
      baseImgUrl: baseImgUrl,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],

      title: '',
      // type:'0',

      // handle: '',
      // batchId: '',
      // status: '',
      loading: false,
      // statusOptions: [
      //     {
      //         value: '',
      //         label: '全部'
      //     },
      //     {
      //         value: 0,
      //         label: '官方模板'
      //     },
      //     {
      //         value: 1,
      //         label: '我的模板'
      //     }
      // ],
      cId: 0,
    }
  },
  created() {
    this.cId = JSON.parse(localStorage.getItem('info')).companyId
  },
  mounted() {
    // this.getTableData()
  },
  methods: {
    delModel(row) {
      this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fetchDelModel(row.id).then(res => {
          this.init()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    init() {
      this.title = ''
      this.pageNum = 1
      this.getTableData()
    },
    changeModelType(row) {
      const param = {
        modelId: row.id
      }
      this.$confirm('此操作将该模板' + (row.type == 0 ? '移出官方模板库' : '加入官方模板库,官方将有一切操作权') + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fetchChangeModelType(param).then(response => {
          this.getTableData()
          this.$message({
            type: 'success',
            message: '成功!'
          });
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    handleSearchData() {
      this.pageNum = 1
      this.getTableData()
    },
    handleReset() {
      this.title = ''
      // this.type = 0
      this.pageNum = 1
      this.getTableData()
    },

    getTableData() {
      let params = {
        title: this.title,
        type: this.type,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      fetchRytModel(params).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
  }
}
</script>
<style scoped>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tableCodeImg {
  width: 100px;
  height: 100px;
}
</style>
